<template>
  <Ethereum :callback="() => {}" />
  <transition name="fade" mode="out-in">
    <div class="wallet-modal-container" v-if="alert.showWalletModal">
      <WalletModal @exit="hideWalletModalDisplay" />
    </div>
  </transition>

  <div class="navBar" :class="navBarStyles">
    <div class="container">
      <router-link v-if="showLogo" to="/" class="logo-wrapper">
        <img
          src="~@/images/header-logo.png"
          alt="Header Logo"
          class="header-logo"
        />
      </router-link>

      <router-link class="link link-text" v-smooth-scroll to="/nfts"
        >NFT</router-link
      >

      <router-link
        v-if="stakingIsOpen"
        class="link link-text"
        v-smooth-scroll
        to="/blood"
        >$BLOOD</router-link
      >

      <router-link
        v-if="claimingIsOpen"
        class="link link-text"
        v-smooth-scroll
        to="/claim"
        >CLAIM</router-link
      >

      <router-link class="link link-text" v-smooth-scroll to="/faq"
        >FAQ</router-link
      >

      <a
        class="link link-text spacer"
        href="https://docs.impostors.gg/"
        target="_blank"
        >DOCS</a
      >

      <div class="link" @click="openUrl('https://twitter.com/PlayImpostors')">
        <TwitterIcon />
      </div>
      <div class="link" @click="openUrl('https://discord.gg/ghv8vjj52D')">
        <DiscordIcon />
      </div>
      <div class="link" @click="openUrl('https://medium.com/@impostors')">
        <MediumIcon />
      </div>

      <div class="menuIcon" @click="showMobileMenu">
        <MobileMenuIcon />
      </div>

      <MobileMenu
        :isVisible="mobileMenuIsVisible"
        :isHome="isHome"
        @toggleVisibility="mobileMenuIsVisible = false"
        @showWalletModal="showWalletModalDisplay"
      />

      <div class="button-container">
        <SkewedDiv
          v-if="ethereum.address && !buttonMsg"
          class="address"
          :class="!showDropdown ? 'dropdown-hidden' : ''"
          @click.stop="toggleDropdown"
          background="var(--color-button-yellow)"
          :skew="[0, -1, 0, [-2, -3]]"
          :borderRadius="6"
        >
          <div class="label">
            <span>{{ profileAddressLabel }}</span>
            <DownArrowIcon
              class="svg-icon"
              :class="showDropdown ? 'flip' : ''"
            />
          </div>
          <div class="profile-dropdown" v-if="showDropdown">
            <!-- <SkewedButton -->
            <!--   v-if="!mintIsOver" -->
            <!--   class="dropdown-item" -->
            <!--   @click="routeTo(`/mint`)" -->
            <!--   background="#9ea406" -->
            <!--   :skew="[0, 0, [-3, 0], [-3, 0]]" -->
            <!--   :borderRadius="3" -->
            <!-- > -->
            <!--   <span class="item-text">Mint</span> -->
            <!-- </SkewedButton> -->
            <router-link
              :to="`/my-items/${ethereum.address}`"
              custom
              v-slot="{ navigate, href }"
            >
              <SkewedButton
                class="dropdown-item"
                as="a"
                :href="href"
                @click="navigate"
                background="#9ea406"
                :skew="[0, 0, [-3, 0], [-3, 0]]"
                :borderRadius="3"
              >
                <span class="item-text">My Items</span>
              </SkewedButton>
            </router-link>
            <router-link
              v-if="stakingIsOpen"
              to="/blood"
              custom
              v-slot="{ navigate, href }"
            >
              <SkewedButton
                class="dropdown-item"
                as="a"
                :href="href"
                @click="navigate"
                background="#9ea406"
                :skew="[0, 0, [-3, 0], [-3, 0]]"
                :borderRadius="3"
              >
                <span class="item-text">Claim $BLOOD</span>
              </SkewedButton>
            </router-link>
            <router-link
              v-if="claimingIsOpen"
              to="/claim"
              custom
              v-slot="{ navigate, href }"
            >
              <SkewedButton
                class="dropdown-item"
                as="a"
                :href="href"
                @click="navigate"
                background="#9ea406"
                :skew="[0, 0, [-3, 0], [-3, 0]]"
                :borderRadius="3"
              >
                <span class="item-text">Claim Items</span>
              </SkewedButton>
            </router-link>
            <SkewedButton
              v-if="isWalletConnectProvider"
              class="dropdown-item"
              @click="disconnectWalletConnectProvider()"
              background="#9ea406"
              :skew="[0, 0, [-3, 0], [-3, 0]]"
              :borderRadius="3"
            >
              <span class="item-text">Disconnect wallet</span>
            </SkewedButton>
          </div>
        </SkewedDiv>

        <!-- <SkewedButton
        v-if="ethereum.address && !buttonMsg"
        class="address"
        :class="addressStyles"
        @click.stop="toggleDropdown"
        background="var(--color-button-yellow)"
        :skew="[0, -1, 0, [-2, -3]]"
        :borderRadius="6"
        >
        <div class="label">
          <span>{{ profileAddressLabel }}</span>
          <DownArrowIcon class="svg-icon" :class="showDropdown ? 'flip' : ''"/>
        </div>

      </SkewedButton>
      <SkewedDiv class="profile-dropdown" v-if="showDropdown" :borderRadius="6" background="var(--color-button-yellow)" :skew="[0, 3, 0, 0]">
        <div class="dropdown-item" @click="routeTo(`/my-items/${ethereum.address}`)">
          <span class="item-text">My Items</span>
        </div>
        </SkewedDiv>-->

        <!-- The user is not connected to a signing provider. -->
        <SkewedButton
          button
          class="address"
          v-if="(!ethereum.address && !isMobile) || buttonMsg"
          @click="handleButtonClicked"
          background="var(--color-button-yellow)"
          :skew="[0, -1, 0, [-3, -3]]"
          :borderRadius="5"
        >
          <span class="wallet-label">{{ walletPrompt }}</span>
        </SkewedButton>
      </div>
    </div>
  </div>
</template>

<script>
'use strict';

// Imports.
import { mapState, mapActions } from 'vuex';
// Component imports.
import WalletModal from '../layout/WalletModal.vue';
import MobileMenu from './MobileMenu.vue';

// Icon imports.
import { useTask } from 'vue-concurrency';
import { ethers } from 'ethers';
import initializeConfig from '@/initialize-config';
import store from '@/store';
import Ethereum from '/src/components/common/Ethereum.vue';
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';
import MediumIcon from '/src/components/icons/MediumIcon';
import MobileMenuIcon from '/src/components/icons/MobileMenuIcon';
import SkewedButton from '/src/components/common/SkewedButton';
import SkewedDiv from '/src/components/common/SkewedDiv';
import DownArrowIcon from '/src/components/icons/DownArrowIcon.vue';

// Set up the default header component.
export default {
  name: 'Header',
  components: {
    DiscordIcon,
    TwitterIcon,
    WalletModal,
    MobileMenu,
    MediumIcon,
    MobileMenuIcon,
    SkewedButton,
    SkewedDiv,
    DownArrowIcon,
    Ethereum
  },
  props: {
    isHome: {
      type: Boolean
    },
    hideLogo: {
      type: Boolean
    },
    buttonMsg: {
      type: String
    }
  },
  data() {
    return {
      showDropdown: false,
      mobileMenuIsVisible: false,
      now: new Date()
    };
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      isWalletConnectProvider: state => state.walletconnect?.connected,
      ethereum: state => state.ethers,
      publicEndTime: state => state.mint?.shopConfig?.publicEndTime
    }),

    walletPrompt() {
      if (this.buttonMsg) {
        return this.buttonMsg;
      } else if (!this.ethereum.address) {
        return 'CONNECT WALLET';
      } else {
        return '...';
      }
    },

    profileAddressLabel() {
      let connectedAddress = this.ethereum.address;
      if (connectedAddress) {
        const shortenedAddress =
          connectedAddress.substring(0, 6) +
          '...' +
          connectedAddress.substring(connectedAddress.length - 4);
        return shortenedAddress;

        // If the user has not connected to a signing address, return no label.
      } else {
        return '';
      }
    },

    mintIsOver() {
      return this.now >= this.publicEndTime;
    },

    stakingIsOpen() {
      const stakeTime = this.$store?.state?.stake?.liveTime;
      if (stakeTime > 0 && this.now.getTime() / 1000 >= stakeTime) return true;
      return false;
    },

    claimingIsOpen() {
      const claimTime = this.$store?.state?.claim?.liveTime;
      if (claimTime > 0 && this.now.getTime() / 1000 >= claimTime) return true;
      return false;
    },

    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    navBarStyles() {
      return this.isHome ? 'homeNavBar' : '';
    },

    showLogo() {
      return !this.isHome && !this.hideLogo;
    }
  },

  mounted() {
    document.addEventListener('click', this.close);

    setInterval(() => {
      this.now = new Date();
    }, 1000);
  },

  onBeforeUnmount() {
    document.removeEventListener('click', this.close);
  },

  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      showWalletModal: 'alert/showWalletModal',
      hideWalletModal: 'alert/hideWalletModal'
    }),
    ...mapActions('ethers', ['connectWallet', 'disconnectWallet']),

    // TODO

    handleButtonClicked() {
      if (this.buttonMsg === 'MINT') {
        this.routeTo('/mint');
      } else {
        this.showWalletModalDisplay();
      }
    },

    showWalletModalDisplay() {
      this.showWalletModal();
    },
    hideWalletModalDisplay() {
      this.hideWalletModal();
    },

    // Connect to an Ethereum wallet.
    connect() {
      this.connectWallet();
    },

    // Disconnect from an Ethereum wallet.
    disconnect() {
      this.disconnectWallet();
    },

    // Disconnect from an wallet connect wallet.
    disconnectWalletConnectProvider() {
      this.$store.commit('disconnectWalletConnectStore');
      store.dispatch('ethers/disconnectWallet');
    },

    // Toggle the dropdown menu display.
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    // Close the dropdown menu.
    close(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },

    // Route to a different path.
    routeTo(path) {
      this.$router.push(path);
    },

    openUrl(url) {
      window.open(url, '_blank');
    },

    showMobileMenu() {
      // Disable scrolling on window.
      window.onscroll = function () {
        window.scrollTo(0, 0);
      };
      this.mobileMenuIsVisible = true;
    }
  },

  // Clear the application alert when routes change.
  watch: {
    $route(to, from) {
      // this.clearAlert();
    }
  }
};
</script>

<style scoped lang="scss">
.navBar {
  //background-color: black;
  padding: 20px;
  position: absolute;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;

  .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    padding-right: 230px;
    position: relative;

    .logo-wrapper {
      width: 200px;
      height: 60px;
      .header-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .logo {
      margin-bottom: 0px;
      cursor: pointer;
      transition: all 300ms ease;

      &:active {
        transform: scale(0.95);
      }

      img {
        width: 150px;
      }
    }

    .spacer {
      margin-right: auto;
    }

    .link-text {
      font-family: DM Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 14px !important;
      line-height: 16px;
    }

    .link {
      display: flex;
      padding: 15px 20px;
      align-items: center;
      border-radius: 10px;
      transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
      color: #fff;
      text-decoration: none;
      font-size: 16px;
      border: none;
      height: fit-content;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }
    }

    .router-link-active {
      color: var(--color-button-yellow);
    }

    .line {
      border-bottom: 2px solid white;
      width: 100px;
    }

    .menuIcon {
      height: 70px;
      width: 70px;
      grid-template-columns: auto;
      grid-row-gap: 5px;
      color: white;
      padding: 20px;
      border-radius: 10px;
      display: none;

      .line {
        border-bottom: 2px solid white;
        width: 30px;
        height: 10px;
        box-sizing: border-box;
        &:last-child {
          margin-bottom: 10px;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: hsla(0, 0%, 100%, 0.1);
      }

      &:active {
        background-color: hsla(0, 0%, 100%, 0.2);
      }
    }

    .connect {
      box-shadow: inset 0 0 0 1px rgb(128, 128, 128);
    }

    .button-container {
      position: absolute;
      right: 20px;
      top: 9px;
      width: 210px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .dropdown-hidden {
        transition: all ease 400ms;
        &:hover {
          // opacity: 0.8;
          // transform: scale(0.97);
          filter: brightness(0.8);
        }

        &:active {
          // opacity: 1;
          // transform: scale(0.97);
          filter: brightness(0.7);
        }
      }

      .address {
        padding: 15px 20px;

        align-items: center;
        text-decoration: none;
        margin-left: 20px;
        min-width: 180px;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        color: #212121;
        cursor: pointer;

        .label {
          display: flex;
          align-items: center;
          gap: 20px;
          padding-left: 12px;

          .svg-icon {
            margin-top: auto;
            margin-bottom: auto;
            height: 10px;
            transition: all ease 200ms;
          }

          .flip {
            transform: rotateZ(180deg);
          }
        }
      }

      .profile-dropdown {
        font-size: 14px;
        z-index: 10;
        color: #212121;
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .dropdown-item {
          padding: 10px;
          text-align: left;
          transition: all 0.2s ease;
          width: 100%;
          text-decoration: none;

          .item-text {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: white;
            align-items: flex-start;
            width: 100%;
            padding-left: 6px;
          }
        }
      }
    }
  }
}

.homeNavBar {
  //background-color: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
}

.wallet-modal-container {
  position: fixed;
  z-index: 9999;
}

.header {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;

  padding: 5px 30px;
  justify-items: center;
  position: relative;
  z-index: 99;
}

.header > img {
  height: 40px;
  grid-column-start: 2;
  transition: ease 0.2s;
}

.header > img:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.header > img:active {
  transform: scale(0.95);
}

.wallet_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 2px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 50px;
  width: 190px;
  background: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.wallet_button:hover {
  cursor: pointer;
  background: var(--background-color);
  color: var(--text-color);
}

.wallet_button:active {
  transform: scale(0.9);
}

.profile_button {
  font-family: 'Work Sans', sans-serif;
  font-size: 1rem;
  border: 0px solid black;
  border-radius: 50px;
  padding: 10px 12px 10px 10px;
  height: 40px;
  width: 160px;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: ease-in 0.2s;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.profile_button:hover {
  cursor: pointer;
  background-color: rgba(var(--purple-accent-rgb), 0.1);
  color: var(--text-color-secondary);
}

.profile_button:active {
  background-color: rgba(var(--purple-accent-rgb), 0.2);
}

.profile_button > p {
  margin-right: 10px;
  margin-left: 10px;
  color: var(--text-color);
  font-size: 14px;
}

.svg-icon {
  margin-top: -5px;
  margin-left: -5px;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}

// Animations
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: all 1s ease;
  transition-delay: 0.5s;
}

.slide-enter-from
// .slide-leave-to
{
  transform: translateY(-100px);
}

@media (max-width: 1050px) {
  .navBar .container {
    justify-content: flex-end;
    padding-right: 0px;
  }
  .navBar .container .link {
    display: none;
  }

  .navBar .container .address {
    display: none;
  }

  .navBar .container .menuIcon {
    display: grid;
  }

  .navBar .container .logo {
    display: initial;
    width: fit-content;
    img {
      max-height: 49px;
    }
  }
}

@media (max-width: 1050px) {
  .header-logo {
    display: none;
  }
}
</style>
