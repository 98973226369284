<template>
  <!-- Menu Navigation -->
  <transition name="fade">
    <div v-show="isVisible" class="mobileBg" @click="triggerClose"></div>
  </transition>

  <transition name="slideright">
    <div v-show="isVisible" class="mobileMenu" @click="triggerClose">
      <div class="closeIcon">
        <CloseIcon />
      </div>
      <div class="link address" v-if="ethereum.address">
        <span></span>
        {{ profileAddressLabel }}
      </div>
      <!-- The user is not connected to a signing provider. -->
      <div class="link" v-if="!ethereum.address" @click="showWalletModalDisplay">{{ walletPrompt }}</div>
      <div v-if="!isHome" class="link" @click="routeTo('/')">HOME</div>

      <!-- <div class="link">
        <a href="/">GAME INFO</a>
      </div>-->
      <router-link
        v-if="ethereum.address"
        class="link"
        :to="`/my-items/${ethereum.address}`"
      >MY ITEMS</router-link>

      <router-link class="link" to="/nfts">NFTs</router-link>

      <!-- <router-link class="link" to="/documents">
        DOCUMENTS
      </router-link>-->

      <router-link v-if="stakingIsOpen" class="link" to="/blood">$BLOOD</router-link>

      <router-link v-if="claimingIsOpen" class="link" to="/claim">CLAIM</router-link>

      <router-link class="link" to="/faq">FAQ</router-link>

      <a class="link" href="https://docs.impostors.gg/" target="_blank">DOCS</a>

      <router-link class="link" to="/terms">TERMS</router-link>

      <router-link class="link" to="/privacy">PRIVACY</router-link>
      <div class="link" @click.self="openUrl('https://discord.gg/ghv8vjj52D')">
        <DiscordIcon />
      </div>
      <div class="link" @click="openUrl('https://twitter.com/PlayImpostors')">
        <TwitterIcon />
      </div>
      <div class="link" @click="openUrl('https://medium.com/@impostors')">
        <MediumIcon />
      </div>
    </div>
  </transition>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { formatAddress } from '/src/utility/format';
import CloseIcon from '/src/components/icons/CloseIcon.vue';
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';
import MediumIcon from '/src/components/icons/MediumIcon.vue';
import useInterval from '/src/utility/useInterval'

export default {
  components: { CloseIcon, DiscordIcon, TwitterIcon, MediumIcon },

  emits: ['toggleVisibility', 'showWalletModal'],

  props: {
    isVisible: Boolean,
    isHome: Boolean
  },

  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const triggerClose = () => {
      window.onscroll = function () { };
      context.emit('toggleVisibility');
    };

    const openUrl = url => {
      window.open(url, '_blank');
    };

    let now = ref(new Date());
    // update 'now' to current time every 1 sec
    useInterval(() => {
      now.value = new Date();
    }, 1000);

    const routeTo = url => {
      router.push(url);
    };

    const ethereum = computed(() => {
      return store.state.ethers;
    });

    const stakingIsOpen = computed(() => {
      const stakeTime = store?.state?.stake?.liveTime;
      if (stakeTime > 0 && now.value.getTime() / 1000 >= stakeTime) return true
      return false;
    });

    const claimingIsOpen = computed(() => {
      const claimTime = store?.state?.claim?.liveTime;
      if (claimTime > 0 && now.value.getTime() / 1000 >= claimTime) return true
      return false;
    });

    const profileAddressLabel = computed(() => {
      return formatAddress(ethereum.value.address);
    });

    const walletPrompt = computed(() => {
      if (!ethereum.value.provider) {
        return '...';
      }
      return ethereum.value.hasLocalProvider
        ? 'Connect Wallet'
        : 'Install a Wallet';
    });

    const showWalletModalDisplay = () => {
      context.emit('showWalletModal');
    };

    return {
      triggerClose,
      openUrl,
      routeTo,
      ethereum,
      profileAddressLabel,
      walletPrompt,
      showWalletModalDisplay,
      stakingIsOpen,
      claimingIsOpen
    };
  }
};
</script>
<style scoped lang="scss">
.mobileMenu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 10px;
  z-index: 10000000000;
  padding: 20px 60px 20px 20px;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: scroll;

  background-color: #2e4eb6;
  font-family: DM Sans;
  color: white;
  display: flex;

  a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }

  a.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }

  .link > a {
    color: #ffffff;
    text-decoration: none;
  }

  .link {
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-radius: 10px;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    background-color: transparent;
    border: none;
    height: fit-content;
    white-space: nowrap;
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
      background-color: hsla(0, 0%, 100%, 0.1);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }

  .address {
    border: 2px solid hsla(0, 0%, 100%, 0.5);
    background-color: hsla(0, 0%, 100%, 0.1);
    cursor: default;
    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background-color: transparent !important;
    }

    span {
      padding: 3px;
      border-radius: 50%;
      background-color: white;
      margin: auto;
      margin-right: 10px;
    }
  }

  .closeIcon {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: hsla(0, 0%, 100%, 0.1);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }
}

.mobileBg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1000vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

// Animations
.slideright-leave-active,
.slideright-enter-active {
  transition: all 0.5s ease;
}

.slideright-enter-from,
.slideright-leave-to {
  transform: translateX(100vw);
}

.fade-leave-active,
.fade-enter-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
