<template>
  <div class="wrapper">
    <Header :isHome="false" />
    <div class="nfts-page">
      <div class="nfts-page__background" />
      <div class="nfts-page__genesis">
        <div class="nfts-page__genesis-container">
          <SkewedDiv
            :border-radius="bubbleSizeConstant * 2"
            :clip-path="`polygon(0% 0%, 100% ${bubbleSizeConstant}px, calc(100% - ${bubbleSizeConstant * 2}px) 100%, ${bubbleSizeConstant * 2}px 100%)`"
            class-content="genesis-bubble__content"
            class-skew-box="genesis-bubble__skew-box"
            class="genesis-bubble"
          >
            <SkewedDiv
              :border-radius="bubbleSizeConstant * 2"
              :clip-path="`polygon(0% 0%, calc(100% - ${bubbleSizeConstant}px) 0%, calc(100% - ${bubbleSizeConstant * 2}px) 100%, ${bubbleSizeConstant * 2}px 100%)`"
              opacity="0.2"
              class-content="genesis-bubble__inner-content"
              class-skew-box="genesis-bubble__inner-skew-box"
              class="genesis-bubble__inner"
            >
              <h2>{{ NFTS_GENESIS_DROP.title }}</h2>
              <img class="genesis-bubble__inner-divider" src="~@/images/genesis-drop-divider.png" />
              <p v-html="NFTS_GENESIS_DROP.subcontent" />
            </SkewedDiv>
          </SkewedDiv>
          <img class="nfts-page__impostor" src="~@/images/genesis-drop-impostor.webp" />
        </div>
      </div>
      <div class="nfts-page__new">
        <NewNFTs id="newnfts" />
      </div>
      <div class="nfts-page__utility">
        <div class="nfts-page__utility-background nfts-page__utility-background-small" />
        <SkewedDiv
          :border-radius="16"
          clip-path="polygon(0 32px, 100% 0, 100% 100%, 0 100%)"
          class-skew-box="nfts-page__utility-background-big-skew-box"
          class-content="nfts-page__utility-background-big-content"
          class="nfts-page__utility-background nfts-page__utility-background-big"
        />
        <img class="nfts-page__utility-impostor" src="~@/images/utility-impostor-figure.webp" />
        <div class="nfts-page__utility-header">
          <h2 class="utility-header">UTILITY</h2>
          <img src="~@/images/gameplay-divider.svg" />
        </div>
        <div class="nfts-page__utility-buttons">
          <SkewedDiv
            class="utility-button utility-button__left"
            background="linear-gradient(92.62deg, #849BE6 -149.9%, #2E4EB6 29.82%)"
            :skew="[[10, 10], [0, 0], [15, 0], [-25, 0]]"
            :borderRadius="15"
            >
              <img class="utility-button__icon" src="~@/images/utility-coins.png" />
              <span class="utility-button__text">earn $BLOOD</span>
          </SkewedDiv>
          <SkewedDiv
            class="utility-button utility-button__right"
            background="linear-gradient(92.62deg, #849BE6 -149.9%, #2E4EB6 29.82%)"
            :skew="[[10, 10], [0, 0], [15, 0], [-25, 0]]"
            :borderRadius="15"
            >
              <img class="utility-button__icon" src="~@/images/utility-game.png" />
              <span class="utility-button__text">use in game</span>
          </SkewedDiv>
          <SkewedDiv
            class="utility-button utility-button__left"
            background="linear-gradient(92.62deg, #849BE6 -149.9%, #2E4EB6 29.82%)"
            :skew="[[10, 10], [0, 0], [15, 0], [-25, 0]]"
            :borderRadius="15"
            >
              <img class="utility-button__icon" src="~@/images/utility-impostor.webp" />
              <span class="utility-button__text">play2earn missions</span>
          </SkewedDiv>
          <div class="utility-button__spacer" />
          <SkewedDiv
            class="utility-button utility-button__right"
            background="linear-gradient(92.62deg, #849BE6 -149.9%, #2E4EB6 29.82%)"
            :skew="[[10, 10], [0, 0], [15, 0], [-25, 0]]"
            :borderRadius="15"
            >
              <img class="utility-button__icon" src="~@/images/utility-fcae.png" />
              <span class="utility-button__text">+ way more...</span>
          </SkewedDiv>
        </div>
      </div>
    </div>
</div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import Header from '@/pages/layout/Header.vue';
import NewNFTs from '../components/NewNFTs.vue';
import SkewedDiv from '@/components/common/SkewedDiv.vue';
import { NFTS_GENESIS_DROP } from '@/constants';

const store = useStore();

const windowSize = ref({ width: window.innerWidth, height: window.innerHeight });

const bubbleSizeConstant = computed(() => windowSize.value.width > 1050 ? 8 : 4);

const onResize = () => {
  windowSize.value = { width: window.innerWidth, height: window.innerHeight };
};

onMounted(() => {
  window.addEventListener('resize', onResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});
</script>

<style scoped lang="scss">
@import '../../../assets/style/mixins.scss';

$gradient-blue: linear-gradient(93.61deg, #849BE6 -9.65%, #2E4EB6 66.26%);

.nfts-page {
  overflow: hidden;
  will-change: transform;
  background-color: var(--color-page-background);
  padding-bottom: 72px;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("~@/images/nfts-page-bg.webp");
    background-size: auto 807px;
    background-position: left top;
    background-repeat: no-repeat;
    z-index: -1;
  }

  &__genesis {
    padding-top: 157px;
    @include flex(column, flex-start);
    min-height: 807px;

    &-container {
      @include flex(row, space-between);
      position: relative;
      max-width: 1470px;
      width: 100%;
      padding: 0 110px;

      .genesis-bubble {
        width: 676px;
        position: relative;

        :deep(.genesis-bubble__skew-box) {
          background: $gradient-blue;
        }

        :deep(.genesis-bubble__content) {
          height: fit-content;
          padding: 22px 16px;
          @include flex(row, stretch, stretch);
        }

        &__inner {
          padding: 32px 34px 46px 54px;

          h2 {
            @include DMSans(44px, 700);
          }

          &-divider {
            margin: 8px 0 32px;
          }

          p {
            @include DMSans(17px, 400);
          }

          :deep(.genesis-bubble__inner-skew-box) {
            background: $gradient-blue;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            height: 100%;
          }

          :deep(.genesis-bubble__inner-content) {
            height: 100%;
          }
        }
      }
    }
  }

  &__utility {
    position: relative;
    height: 726px;
    width: 100%;
    z-index: 2;
    @include flex(column, flex-start, center);
    margin-bottom: 100px;

    &-background {
      position: absolute;
      width: 100%;

      &-big {
        height: calc(100% + 200px);
        bottom: -72px;

        :deep(.nfts-page__utility-background-big-skew-box) {
          background: url("~@/images/utility-bg.webp") no-repeat bottom/cover;
        }

        &:after {
          content: '';
          position: absolute;
          background-color: var(--color-page-background);
          width: 100%;
          height: 72px;
          bottom: 0;
        }
      }

      &-small {
        display: none;
      }
    }

    &-impostor {
      position: absolute;
      bottom: -116px;
      left: 10%;
      z-index: 3;
    }

    &-header {
      margin-top: -32px;
      z-index: 2;
      h2 {
        @include DMSans(44px, 700);
        text-align: center;
      }
      img {
        width: 160px;
      }
    }

    &-buttons {
      margin-top: 48px;
      @include flex(row, center, center, wrap);
      column-gap: 36px;
      row-gap: 60px;
      width: 920px;
      .utility-button {
        position: relative;
        height: 106px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        :deep(.content) {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          }
        &__icon {
          height: 94px;
          position: absolute;
          top: center;
          left: -40px;
        }
        &__text {
          height: 100%;
          @include flex();
          @include DMSans(22px, 700);
          color: var(--color-white);
          padding: 0 30px 0 60px;
        }

        &__spacer {
          width: 250px;
        }
      }
    }
  }
}

@include mediaOnlyScreenMax(1280px) {
  .nfts-page {

    &__genesis {

      &-container {
        padding: 0 84px;
      }
    }

    &__utility {
      height: 685px;

      &-impostor {
        height: 480px;
      }
    }
  }
}

@include mediaOnlyScreenMax(1050px) {
  .nfts-page {

    &__background {
      background-size: auto 720px;
    }

    &__impostor {
      position: absolute;
      height: 313px;
      top: 320px;
    }

    &__genesis {
      padding-top: 128px;
      min-height: 720px;

      &-container {
        @include flex(row);
        padding: 0 24px;

        .genesis-bubble {

          :deep(.genesis-bubble__content) {
            height: fit-content;
            padding: 22px 16px;
            @include flex(row, stretch, stretch);
          }

          &__inner {
            padding: 28px 22px 34px;

            :deep(.genesis-bubble__inner-content) {
              @include flex(column, flex-start, center);
            }

            h2 {
              text-align: center;
            }

            p {
              text-align: center;
            }
          }
        }
      }
    }
    &__utility {
      position: relative;
      height: 680px;
      margin-top: 100px;
      margin-bottom: 20px;

      &-background {

        &-big {
          display: none;
        }

        &-small {
          display: initial;
          width: 100%;
          height: 100%;
          top: 0;
          clip-path: polygon(0 16px, 100% 0, 100% 100%, 0 100%);
          background: url("~@/images/utility-bg.png") no-repeat bottom/cover;
        }
      }

      &-header {
        margin-top: 64px;

        h2 {
          @include DMSans(32px, 700);
          text-align: center;
        }

        img {
          width: 160px;
        }
      }

      &-impostor {
        height: 300px;
        bottom: -56px;
      }

      &-buttons {
        margin-top: 18px;
        padding: 0 24px;

        .utility-button {
          position: relative;
          height: 106px;
          padding: 16px;

          &__icon {
            height: 94px;
            position: absolute;
            top: center;
            left: -40px;
          }

          &__text {
            height: 100%;
            @include flex();
            @include DMSans(22px, 700);
            color: var(--color-white);
            padding: 0 30px 0 60px;
          }

          &__spacer {
            display: none;
          }
        }
      }
    }
  }
}

@include mediaOnlyScreenMax(640px) {
  .nfts-page {

    &__utility {
    height: 820px;

      &-buttons {
        width: 100%;
        margin-top: 16px;
        margin-left: 48px;
        margin-right: 48px;
        row-gap: 12px;
        @include flex(column, flex-start, center);

        .utility-button {
          position: relative;
          height: 106px;
          padding: 16px;

          &__left {
            align-self: flex-start;
          }

          &__right {
            align-self: flex-end;
          }

          &__icon {
            height: 94px;
            position: absolute;
            top: center;
            left: -40px;
          }

          &__text {
            height: 100%;
            @include flex();
            @include DMSans(22px, 700);
            color: var(--color-white);
            padding: 0 30px 0 60px;
          }

          &__spacer {
            display: none;
          }
        }
      }
    }

    &__genesis {

      &-container {

        .genesis-bubble {

          &__inner {

            h2 {
              @include DMSans(28px, 700);
            }

            &-divider {
              margin: 8px 0 32px;
              width: 198px;
            }

            p {
              @include DMSans(14px, 400);
            }
          }
        }
      }
    }
  }
}
</style>
