'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethersService } from './index';
import { ethers } from 'ethers';
import { errMsg, processError } from '../utility';
import axios from 'axios';
import { l } from '@/datadog';
const logName = 'claim.service.js';
import { flag } from '@/featureflag';

import {
  IMPOSTOR_TOKEN_TYPE,
  UFO_TOKEN_TYPE,
  PET_TOKEN_TYPE,
  BOX_TOKEN_TYPE,
  CHEST_TOKEN_TYPE,
  PASS_TOKEN_TYPE,
  VOXEL_TOKEN_TYPE
} from '@/constants';


// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

// Taps into launchdarkly for feature flags for contract addresses
const getRedeemerAddress = async function({ networkId }) {
  if (!config) {
    config = await initializeConfig();
  }

  if (!networkId) {
    return config.redeemerAddress[config.forceNetwork];
  }

  if (networkId == '0x1') {
    return config.redeemerAddress[networkId];
  }

  return flag.qaRedeemerContractAddress.value;
}

// attempts to hit contract. on failure,
// flags caller.
// returns: error message (string)
export const checkValidProvider = async () => {
  try {
    // find out if network is valid (or fail)
    let p = await ethersService.getProvider();
    // check what current provider network is using
    let n = await p.getNetwork();
    if (config.forceNetwork != n.chainId) {
      // if current network is not the one user in enforcing, fail
      return errMsg('WrongNetwork');
    }
    // attempts to call expected function from known redeemer contract
    let networkId = ethers.utils.hexValue(n.chainId);
    let s = await getRedeemerAddress(networkId);
    let c = new ethers.Contract(s, config.redeemerABI, p);
    await c.redemptionConfigs(0);
    return null;
  } catch (error) {
    console.error(error)
    return errMsg('WrongNetwork');
  }
};

// Parse the allowance and number of token owned by a given address.
const loadTokenInfo = async function({ dispatch }) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let bloodAddress = config.bloodTokenAddress[networkId];
  let redeemerAddress = await getRedeemerAddress(networkId);

  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();

  let erc20Contract = new ethers.Contract(
    bloodAddress,
    config.erc20ABI,
    signer
  );
  let bloodBalance = await erc20Contract.balanceOf(walletAddress);
  let redeemerAllowance = await erc20Contract.allowance(walletAddress, redeemerAddress);

  let amtF = () => ethers.utils.formatEther(redeemerAllowance);
  let balances = {
    bloodBalance: ethers.utils.formatEther(bloodBalance),
    redeemerAllowance: amtF(),
	hasAllowance: (qnt = 0) => ethers.utils.parseEther(amtF()).gt(ethers.utils.parseEther(qnt))
  }
  return balances;
};

const approveRedeemer = async function({ dispatch }) {
  l.dbg(this, { f: [logName, 'approveRedeemer'] });

  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let signer = await provider.getSigner();

  let bloodAddress = config.bloodTokenAddress[networkId];
  let redeemerAddress = await getRedeemerAddress(networkId);

  let bloodContract = new ethers.Contract(
    bloodAddress,
    config.erc20ABI,
    signer
  );

  let approvalTx = null;
  try {
    approvalTx = await bloodContract.approve(
      redeemerAddress,
      ethers.constants.MaxUint256
    );
  } catch (error) {
    await processError(errMsg(error.message), true, dispatch);
  }

  if (approvalTx != null) {
    await dispatch(
      'alert/info',
      {
        message: 'Transaction Submitted',
        metadata: {
          transaction: approvalTx.hash
        },
        duration: 300000
      },
      { root: true }
    );

    await approvalTx
      .wait()
      .then(async result => {
        //console.info('Result from approval attempt', result);
        await dispatch('alert/clear', '', { root: true });
        await dispatch(
          'alert/info',
          {
            message: 'Transaction Confirmed',
            metadata: {
              transaction: approvalTx.hash
            },
            duration: 10000
          },
          { root: true }
        );
      })
      .catch(async function(error) {
        await processError(errMsg(error.message), true, dispatch);
      });
  }
};

const getClaimDetails = async function({ dispatch = () => { } } = {}) {
  //l.dbg(this, { f: logName + l.SEP + 'getClaimDetails' });

  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let redeemerAddress = await getRedeemerAddress(networkId);

  let redeemerContract = new ethers.Contract(
    redeemerAddress,
    config.redeemerABI,
    provider
  );

  let ufoClaimCfg = config.redeemerClaimConfig["ufoCollections"];
  let ufoCfg = await redeemerContract.redemptionConfigs(ufoClaimCfg.round);
  let ufoTokenAddrs = [];
  for (let x = 0; x < ufoClaimCfg.numCriteria; x++) {
    let ufoTokenAddr = await redeemerContract.redemptionCriteria(ufoClaimCfg.round, x);
    ufoTokenAddrs.push(ufoTokenAddr);
  }

  let petClaimCfg = config.redeemerClaimConfig["petCollections"];
  let petCfg = await redeemerContract.redemptionConfigs(petClaimCfg.round);
  let petTokenAddrs = [];
  for (let x = 0; x < petClaimCfg.numCriteria; x++) {
    let petTokenAddr = await redeemerContract.redemptionCriteria(petClaimCfg.round, x);
    petTokenAddrs.push(petTokenAddr);
  }

  let boxClaimCfg = config.redeemerClaimConfig["boxCollections"];
  let boxCfg = await redeemerContract.redemptionConfigs(boxClaimCfg.round);
  let boxTokenAddrs = [];
  for (let x = 0; x < boxClaimCfg.numCriteria; x++) {
    let boxTokenAddr = await redeemerContract.redemptionCriteria(boxClaimCfg.round, x);
    boxTokenAddrs.push(boxTokenAddr);
  }

  let ret = {
    impostorsCollectionAddress: ufoTokenAddrs[0],
    [config.ufoCollections[networkId]]: {
      amountOut: ufoCfg.amountOut.toString(),
      payingToken: ufoCfg.payingToken,
      price: parseInt(ethers.utils.formatEther(ufoCfg.price.toString()).toString()),
      tokenOut: ufoCfg.tokenOut,
      criteria: ufoTokenAddrs,
      round: ufoClaimCfg.round
    },
    ufoCollectionAddress: config.ufoCollections[networkId],
    [config.petCollections[networkId]]: {
      amountOut: petCfg.amountOut.toString(),
      payingToken: petCfg.payingToken,
      price: parseInt(ethers.utils.formatEther(petCfg.price.toString()).toString()),
      tokenOut: petCfg.tokenOut,
      criteria: petTokenAddrs,
      round: petClaimCfg.round
    },
    petCollectionAddress: config.petCollections[networkId],
    [config.boxCollections[networkId]]: {
      amountOut: boxCfg.amountOut.toString(),
      payingToken: boxCfg.payingToken,
      price: parseInt(ethers.utils.formatEther(boxCfg.price.toString()).toString()),
      tokenOut: boxCfg.tokenOut,
      criteria: boxTokenAddrs,
      round: boxClaimCfg.round
    },
    boxCollectionAddress: config.boxCollections[networkId]
  };

  if (flag.chestEnabled.value) {
    let chestClaimCfg = config.redeemerClaimConfig["chestCollections"];
    let chestCfg = await redeemerContract.redemptionConfigs(chestClaimCfg.round);
    let chestTokenAddrs = [];
    for (let x = 0; x < chestClaimCfg.numCriteria; x++) {
      let chestTokenAddr = await redeemerContract.redemptionCriteria(chestClaimCfg.round, x);
      chestTokenAddrs.push(chestTokenAddr);
    }

    ret[config.chestCollections[networkId]] = {
      amountOut: chestCfg.amountOut.toString(),
      payingToken: chestCfg.payingToken,
      price: parseInt(ethers.utils.formatEther(chestCfg.price.toString()).toString()),
      tokenOut: chestCfg.tokenOut,
      criteria: chestTokenAddrs,
      round: chestClaimCfg.round
    };
    ret['chestCollectionAddress'] = config.chestCollections[networkId];
  };

  if (flag.voxelEnabled.value) {
    let voxelClaimCfg = config.redeemerClaimConfig['voxelCollections'];

    let voxelCfg = await redeemerContract.redemptionConfigs(
      voxelClaimCfg.round
    );
    let voxelTokenAddrs = [];
    for (let x = 0; x < voxelClaimCfg.numCriteria; x++) {
      let voxelTokenAddr = await redeemerContract.redemptionCriteria(
        voxelClaimCfg.round, x);
		voxelTokenAddrs.push(voxelTokenAddr);
    }

    ret[config.voxelCollections[networkId]] = {
		amountOut: voxelCfg.amountOut.toString(),
		payingToken: voxelCfg.payingToken,
      	price: parseInt(
			ethers.utils.formatEther(voxelCfg.price.toString()).toString()
		),
		tokenOut: voxelCfg.tokenOut,
		criteria: voxelTokenAddrs,
		round: voxelClaimCfg.round
    };
	ret['voxelCollectionAddress'] = config.voxelCollections[networkId];
  }

//   if (flag.passEnabled.value) {
//     let passClaimCfg = config.redeemerClaimConfig["passCollections"];
//     let passCfg = await redeemerContract.redemptionConfigs(passClaimCfg.round);
//     let passTokenAddrs = [];
//     for (let x = 0; x < passClaimCfg.numCriteria; x++) {
//       let passTokenAddr = await redeemerContract.redemptionCriteria(passClaimCfg.round, x);
//       passTokenAddrs.push(passTokenAddr);
//     }

//     ret[config.passCollections[networkId]] = {
//       amountOut: passCfg.amountOut.toString(),
//       payingToken: passCfg.payingToken,
//       price: parseInt(ethers.utils.formatEther(passCfg.price.toString()).toString()),
//       tokenOut: passCfg.tokenOut,
//       criteria: passTokenAddrs,
//       round: passClaimCfg.round
//     };
//     ret['passCollectionAddress'] = config.passCollections[networkId];
//   }

  return ret;
}

// Parse the items owned by a given address.
const loadEligibleItems = async function(
  { resolveMetadata, dispatch }
) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  // Check for token spend approval.
  let signer = await provider.getSigner();
  let walletAddress = await signer.getAddress();
  l.dbg(this, { f: [logName, 'loadEligibleItems', walletAddress] });

  let redeemerAddress = await getRedeemerAddress(networkId);
  let redeemerContract = new ethers.Contract(
    redeemerAddress,
    config.redeemerABI,
    provider
  );

  let impostorsCollectionAddress = {
    addr: config.itemCollections[networkId],
    collectionRounds: [
      'ufoCollections',
      'petCollections',
      'boxCollections',
      'chestCollections',
	  'passCollections',
      'voxelCollections'
    ]
  };
  let ufoCollectionAddress = {
    addr: config.ufoCollections[networkId],
    collectionRounds: [
      'petCollections',
      'boxCollections',
      'chestCollections',
      'passCollections'
    ]
  };
  let petCollectionAddress = {
    addr: config.petCollections[networkId],
    collectionRounds: ['boxCollections', 'chestCollections', 'passCollections']
  };
  let boxCollectionAddress = {
    addr: config.boxCollections[networkId],
    collectionRounds: ['chestCollections', 'passCollections']
  };
  let chestCollectionAddress = {
    addr: config.chestCollections[networkId],
    collectionRounds: ['passCollections']
  };
  let passCollectionAddress = {
    addr: config.passCollections[networkId],
    collectionRounds: []
  };
  let voxelCollectionAddress = {
    addr: config.voxelCollections[networkId],
    collectionRounds: []
  };

  // determine this user's current inventory.
  let collections = [
    impostorsCollectionAddress,
    ufoCollectionAddress,
    petCollectionAddress,
    boxCollectionAddress,
    chestCollectionAddress,
    passCollectionAddress,
	voxelCollectionAddress
  ];
  let owned = {};
  let unredeemed = {};

  for (let i = 0; i < collections.length; i++) {
    // ignore any empty addresses
    if (!collections[i].addr) continue;

    let itemContract = new ethers.Contract(
      collections[i].addr,
      config.itemABI,
      provider
    );

    // Check relative item transfer events to determine this user's current
    // inventory.
    let ownershipData = {};

    // Single transfer events.
    let filterToWallet = itemContract.filters.Transfer(null, walletAddress)
    let filterFromWallet = itemContract.filters.Transfer(walletAddress, null)
    let singleTransfers = [
      ...await safeQueryFilter(itemContract, filterToWallet),
      ...await safeQueryFilter(itemContract, filterFromWallet),
    ].sort((a, b) => {
      let block = a.blockNumber - b.blockNumber
      if (block !== 0) {
        return block
      }

      return a.transactionIndex - b.transactionIndex
    })

    for (let t of singleTransfers) {
      ownershipData[t.args.tokenId.toNumber()] = {
        collectionAddress: t.address,
        tokenId: t.args.tokenId.toString(),
        blockHash: t.blockHash,
        blockNumber: t.blockNumber,
        data: t.data,
        txHash: t.transactionHash,
        txIndex: t.transactionIndex,
        metadata: null,
        owner: t.args.to
      };
    }

    // to reduce number of calls to contract/transfer,
    // we leverage this function call to also load all owned
    // metadata
    let collectionOwned = Object.values(ownershipData)
      .filter((tokenData) => tokenData.owner === walletAddress);

    // load metadata for owned items
    const metadataURI = await itemContract.metadataUri();
    for (let item of collectionOwned) {
      let resp = {
        image: `https://impostors-genesis.mypinata.cloud/ipfs/QmWz3XTv2zdq8Pz5reEKgMMNPs5kQ8TJXUpJhb2B67aSgD/${item.tokenId}.png`
      };
      if (resolveMetadata) {
        resp = await axios
          .get(metadataURI + item.tokenId)
          .then(resp => {
            return resp.data;
          })
          .catch(err => {
            return { err: err.message };
          });
      }
      item.metadata = resp

      switch (collections[i].addr) {
        case config.itemCollections[config.forceNetwork]:
          item.title = IMPOSTOR_TOKEN_TYPE.toUpperCase();
          item.tokenType = IMPOSTOR_TOKEN_TYPE;
          break;
        case config.ufoCollections[config.forceNetwork]:
          item.title = UFO_TOKEN_TYPE.toUpperCase();
          item.tokenType = UFO_TOKEN_TYPE;
          break;
        case config.petCollections[config.forceNetwork]:
          item.title = PET_TOKEN_TYPE.toUpperCase();
          item.tokenType = PET_TOKEN_TYPE;
          break;
        case config.boxCollections[config.forceNetwork]:
          item.title = BOX_TOKEN_TYPE.toUpperCase();
          item.tokenType = BOX_TOKEN_TYPE;
          break;
        case config.chestCollections[config.forceNetwork]:
          item.title = CHEST_TOKEN_TYPE.toUpperCase();
          item.tokenType = CHEST_TOKEN_TYPE;
          break;
        case config.passCollections[config.forceNetwork]:
          item.title = PASS_TOKEN_TYPE.toUpperCase();
          item.tokenType = PASS_TOKEN_TYPE;
          break;
		case config.voxelCollections[config.forceNetwork]:
			item.title = VOXEL_TOKEN_TYPE.toUpperCase();
			item.tokenType = VOXEL_TOKEN_TYPE;
			break;
      }

      item.tokenId = parseInt(item.tokenId);
    }
    // store list of the owned items (at this collection address)
    owned[collections[i].addr] = collectionOwned;

    // for every relevant round, identify redeemed items

    // generate array of all owned items (required for 'isRedeemed' contract call)
    let aOwned = []
    for (let item of collectionOwned) {
      aOwned.push(item.tokenId);
    }

    // for every round (that this nft can be part of) find the list of unredeemed
    unredeemed[collections[i].addr] = [];
    for (let j = 0; j < collections[i].collectionRounds.length; j++) {
      let round = config.redeemerClaimConfig[collections[i].collectionRounds[j]].round;
      let redeemed = await redeemerContract.isRedeemed(round, collections[i].addr, aOwned);
      let redeemedItems = aOwned.filter((element, index) => redeemed[index]);
      let unredeemedItems = Object.values(collectionOwned)
        .filter((tokenData) => !redeemedItems.includes(tokenData.tokenId));
      unredeemed[collections[i].addr].push({
        claim: collections[i].collectionRounds[j],
        round: round,
        items: unredeemedItems
      });
    }
  }

  //console.info("owned", owned);
  //console.info("unredeemed", unredeemed);

  return {
    eligible: unredeemed,
    owned: owned,
    collections: {
      [IMPOSTOR_TOKEN_TYPE]: impostorsCollectionAddress,
      [UFO_TOKEN_TYPE]: ufoCollectionAddress,
      [PET_TOKEN_TYPE]: petCollectionAddress,
      [BOX_TOKEN_TYPE]: boxCollectionAddress,
      [CHEST_TOKEN_TYPE]: chestCollectionAddress,
	  [PASS_TOKEN_TYPE]: passCollectionAddress,
      [VOXEL_TOKEN_TYPE]: voxelCollectionAddress
    }
  };
};

const safeQueryFilter = async function(contract, event, startBlock, endBlock) {
  let start = startBlock
  // let end = await provider.getBlockNumber()
  let end = endBlock
  let endRange = end

  let results = []
  do {
    if (start >= endRange) {
      endRange = end
    }
    let singleTransfers = []
    try {
      singleTransfers = await contract.queryFilter(event, start, endRange);
    } catch (e) {
      let mid = Math.round((start + endRange) / 2)
      endRange = mid
      continue
    }
    results = results.concat(singleTransfers)
    start = endRange + 1
  } while (endRange < end)
  return results
};

const loadItemMetadata = async function({ collectionAddress, tokenId, dispatch }) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let isValid = ethers.utils.isAddress(collectionAddress);
  if (!isValid) {
    return; // TODO: throw useful error.
  }

  let provider;
  try {
    provider = await ethersService.getProvider();
  } catch (error) {
    await processError(err, false, dispatch);
    return;
  }

  let itemContract = new ethers.Contract(
    collectionAddress,
    config.itemABI,
    provider
  );

  const metadataURI = await itemContract.metadataUri();

  return axios
    .get(metadataURI + tokenId)
    .then(resp => {
      //console.warn(`Loaded tokenId=${tokenId} at collection=${collectionAddress}`, resp.data);
      return resp.data;
    })
    .catch(err => {
      return { err: err.message };
    });
}

async function loadItemById({collection, tokenId, dispatch} = {}) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();

  let {
    collectionAddress,
    title,
    tokenType,
  } = {
    "voxel": {
		collectionAddress: config.voxelCollections[config.forceNetwork],
		title: VOXEL_TOKEN_TYPE.toUpperCase(),
		tokenType: VOXEL_TOKEN_TYPE
    }
  }[collection]

  let itemContract = new ethers.Contract(
    collectionAddress,
    config.itemABI,
    provider
  );

  let metadata = await loadItemMetadata({
    collectionAddress,
    tokenId,
    dispatch,
  });
  let owner = await itemContract.ownerOf(tokenId)

  return {
    tokenId,
    title,
    tokenType,
    collectionAddress,
    owner,
    metadata
  }
}

const redeem = async function(
  { round, tokenIds, dispatch },
  { onError = () => { } } = {}
) {
  l.dbg(this, { f: [logName, 'redeem'], p: [round, tokenIds] });

  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let signer = await provider.getSigner();
  let redeemerAddress = await getRedeemerAddress(networkId);

  let redeemerContract = new ethers.Contract(
    redeemerAddress,
    config.redeemerABI,
    signer
  );

  let walletAddress = await signer.getAddress();
  l.dbg(this, { f: [logName, walletAddress] });

  let redemptionTx = null;
  try {
    //console.warn("Calling redeem with params:", round, tokenIds);
    redemptionTx = await redeemerContract.redeem(round, tokenIds);
  } catch (error) {
    let errorMessage = errMsg(error.message);
    await processError(errorMessage, true, dispatch);
    onError(errorMessage);
  }

  if (redemptionTx != null) {
    await dispatch(
      'alert/info',
      {
        message: 'Transaction Submitted',
        metadata: {
          transaction: redemptionTx.hash
        },
        duration: 300000
      },
      { root: true }
    );

    await redemptionTx
      .wait()
      .then(async result => {
        //console.info('Result from approval attempt', result);
        await dispatch('alert/clear', '', { root: true });
        await dispatch(
          'alert/info',
          {
            message: 'Transaction Confirmed',
            metadata: {
              transaction: redemptionTx.hash
            },
            duration: 10000
          },
          { root: true }
        );
      })
      .catch(async function(error) {
        let errorMessage = errMsg(error.message);
        await processError(errorMessage, true, dispatch);
        onError(errorMessage);
      });
  }
};

const checkEligibility = async function({ collection, tokenId, dispatch }) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let signer = await provider.getSigner();

  let redeemerAddress = await getRedeemerAddress(networkId);
  let redeemerContract = new ethers.Contract(
    redeemerAddress,
    config.redeemerABI,
    signer
  );

  let collectionAddress = collection[networkId];
  let tokenContract = new ethers.Contract(
    collectionAddress,
    config.itemABI,
    provider
  );

  let eligibility = {
    collectionAddress: collectionAddress,
    tokenId: tokenId
  };

  // check whether token exists
  let exists = await tokenContract._exists(tokenId);
  eligibility['minted'] = exists;

  // claim ufos
  let ufoClaimCfg = config.redeemerClaimConfig["ufoCollections"];
  let ufoRedeemed = await redeemerContract.isRedeemed(ufoClaimCfg.round, collectionAddress, [tokenId]);
  eligibility['ufoEligible'] = !ufoRedeemed[0];

  // claim pets
  let petClaimCfg = config.redeemerClaimConfig["petCollections"];
  let petRedeemed = await redeemerContract.isRedeemed(petClaimCfg.round, collectionAddress, [tokenId]);
  eligibility['petEligible'] = !petRedeemed[0];

  // claim boxes
  let boxClaimCfg = config.redeemerClaimConfig["boxCollections"];
  let boxRedeemed = await redeemerContract.isRedeemed(boxClaimCfg.round, collectionAddress, [tokenId]);
  eligibility['boxEligible'] = !boxRedeemed[0];

  // claim chests
  let chestClaimCfg = config.redeemerClaimConfig["chestCollections"];
  let chestRedeemed = await redeemerContract.isRedeemed(chestClaimCfg.round, collectionAddress, [tokenId]);
  eligibility['chestEligible'] = !chestRedeemed[0];

  // claim pass
  let passClaimCfg = config.redeemerClaimConfig["passCollections"];
  let passRedeemed = await redeemerContract.isRedeemed(passClaimCfg.round, collectionAddress, [tokenId]);
  eligibility['passEligible'] = !passRedeemed[0];

  // claim voxel
  let voxelClaimCfg = config.redeemerClaimConfig["voxelCollections"];
  let voxelRedeemed = await redeemerContract.isRedeemed(voxelClaimCfg.round, collectionAddress, [tokenId]);
  eligibility['voxelEligible'] = !voxelRedeemed[0];

  return eligibility;
};

// redundant and specialized function that takes list of item ids + round, and returns array of redeemed status
const checkRedeemStatus = async function({ round, collection, tokenIds, dispatch }) {
  if (!config) {
    config = await initializeConfig();
  }

  const err = await checkValidProvider();
  if (err) {
    await processError(err, false, dispatch);
    return;
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
  let signer = await provider.getSigner();
  let redeemerAddress = await getRedeemerAddress(networkId);
  let redeemerContract = new ethers.Contract(
    redeemerAddress,
    config.redeemerABI,
    signer
  );

  let redeemResults = await redeemerContract.isRedeemed(round, collection, tokenIds);
  return {
    results: redeemResults
  }
};

// Export the user service functions.
export const claimService = {
  loadTokenInfo,
  approveRedeemer,
  getClaimDetails,
  loadEligibleItems,
  loadItemMetadata,
  redeem,
  checkEligibility,
  checkRedeemStatus,
  loadItemById,
};
