'use strict';

import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import config from './config';
import { computed, ref } from 'vue';

let client = null;
let refs = ref(config.flags);

const anon = {
  key: 'anon'
};

const init = async () => {
  config.launchdarkly.user = anon;
  client = LaunchDarkly.initialize(config.launchdarkly.clientSideId, config.launchdarkly.user);
  try {
    await client.waitUntilReady();
    await refresh();
    client.on('change', async () => {
      await refresh();
    });
  } catch (err) {
    // initialization failed
    console.error(err);
  }
}

const unsetUser = async () => {
  config.launchdarkly.user = anon;
  client.identify(config.launchdarkly.user);
}

const setUser = async (addr) => {
  if (!addr) {
    config.launchdarkly.user = anon;
  } else {
    config.launchdarkly.user = {
      key: addr.toUpperCase()
    };
  }
  client.identify(config.launchdarkly.user);
}

const flags = () => {
  let flags = [];
  for (let idx = 0; idx < config.ALL_FLAGS; idx++) {
    const idxNaming = idx;
    let flag = {
      ...config.flags[idxNaming]
    }
    for (let item in config.flags) {
      if (item == idxNaming) {
        flag['name'] = item;
        flag['index'] = idx;
        break;
      }
    }
    flags.push(flag);
  }
  return flags;
};

const refresh = async () => {
  // hit launchdarkly and populate all flags
  const all = flags();
  for (let f in all) {
    let key = all[f].key;
    const status = await client.variation(key, true);
    let index = all[f].index;
    refs.value[index].status = status;
  }
}

const chestEnabled = computed(() => refs.value[0].status);
const chestLiveTime = computed(() => refs.value[1].status);

const petEnabled = computed(() => refs.value[2].status);
const petLiveTime = computed(() => refs.value[3].status);

const boxEnabled = computed(() => refs.value[4].status);
const boxLiveTime = computed(() => refs.value[5].status);

const passEnabled = computed(() => refs.value[6].status);
const passLiveTime = computed(() => refs.value[7].status);

const qaRedeemerContractAddress = computed(() => refs.value[8].status);

const stakerV2Enabled = computed(() => refs.value[9].status);

const voxelEnabled = computed(() => refs.value[10].status);
const voxelLiveTime = computed(() => refs.value[11].status);

// Export the user service functions.
export {
  init,
  setUser,
  unsetUser,
  petEnabled,
  petLiveTime,
  boxEnabled,
  boxLiveTime,
  chestEnabled,
  chestLiveTime,
  passEnabled,
  passLiveTime,
  voxelEnabled,
  voxelLiveTime,
  qaRedeemerContractAddress,
  stakerV2Enabled
};
