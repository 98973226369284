'use strict';

// Imports.
import { claimService } from '../../services';
import { processError, errMsg } from '../../utility';

// Specified actions for mutating the alert state.
export default {
  async loadTokenInfo(
    { dispatch, commit },
    { },
  ) {
    try {
      let balances = await claimService.loadTokenInfo({ dispatch });
      commit('updateTokenInfo', balances);

      // Catch errors.
    } catch (error) {
      await processError(errMsg(error), false, dispatch);
    }
  },

  async approveRedeemer(
    { dispatch, commit },
    { },
  ) {
    try {
      let redemption = await claimService.approveRedeemer({ dispatch });

      // Catch errors.
    } catch (error) {
      await processError(errMsg(error), false, dispatch);
    }
  },

  async getClaimDetails(
    { dispatch, commit },
    { },
  ) {
    try {
      let claimDetails = await claimService.getClaimDetails({ dispatch });
      commit('updateClaimDetails', claimDetails)

      // Catch errors.
    } catch (error) {
      await processError(errMsg(error), false, dispatch);
    }
  },

  async loadEligibleItems(
    { dispatch, commit },
    { resolveMetadata, claimDetails },
  ) {
    try {
      let eligible = await claimService.loadEligibleItems({ resolveMetadata, dispatch });
      commit('updateEligible', eligible);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await processError(errMsg(error), false, dispatch);
    }
  },

  async loadItemMetadata({ dispatch, commit }, { collectionAddress, tokenId }) {
    try {
      //console.log('loading token metadata', tokenId, 'contract', collectionAddress)
      let metadata = await claimService.loadItemMetadata({ collectionAddress, tokenId, dispatch });
      commit('updateMetadata', { collectionAddress, tokenId, metadata });
    } catch (error) {
      await processError(errMsg(error), false, dispatch);
    }
  },

  async redeem(
    { dispatch, commit },
    { round, tokenIds },
  ) {
    try {
      await claimService.redeem({ round, tokenIds, dispatch });

      // Catch errors.
    } catch (error) {
      await processError(errMsg(error), false, dispatch);
    }
  },

  async checkRedeemStatus(
    { dispatch, commit },
    { round, collection, tokenIds },
  ) {
    try {
      let redeemStatus = await claimService.checkRedeemStatus({ round, collection, tokenIds, dispatch });
      commit('updateRedeemStatus', redeemStatus);

      // Catch and notify the user of a purchase error.
    } catch (error) {
      await processError(errMsg(error), false, dispatch);
    }
  },
};
